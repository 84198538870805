import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Box,
  SwipeableDrawer,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

// スタイル付きコンポーネントの定義
const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const DrawerItem = styled(Button)<ButtonProps>(({ theme }) => ({
  justifyContent: "flex-start",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Button の props の型を拡張
interface ButtonProps extends React.ComponentProps<typeof Button> {
  to?: string;
}

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const menuItems = [
    { text: "ホーム", path: "/" },
    { text: "会社概要", path: "/about" },
    { text: "業務内容", path: "/business" },
    { text: "求人情報", path: "/jobs" },
  ];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const drawerList = (
    <Box sx={{ width: 280 }}>
      <DrawerHeader>
        <Typography variant="h6" color="white">
          メニュー
        </Typography>
        <IconButton color="inherit" onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <DrawerItem
              as={Link}
              to={item.path}
              style={{ width: "100%" }}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={item.text} />
            </DrawerItem>
          </ListItem>
        ))}
        <ListItem
          disablePadding
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: 20,
              backgroundColor: "#f47754",
              color: "white",
              "&:hover": {
                backgroundColor: "#e05634",
              },
              minWidth: "auto", // ボタンの最小幅を自動に設定
              width: "auto", // ボタンの幅を自動に設定
              whiteSpace: "nowrap", // テキストの折り返しを防ぐ
              mt: 1,
            }}
            href="https://lin.ee/oGoOopX"
            target="_blank"
            size="small"
          >
            LINEでお問い合わせ
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "white", boxShadow: "none" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <img
          src="images/logo.png"
          alt="logo"
          style={{ height: 40, cursor: "pointer" }}
          onClick={() => (window.location.href = "/")}
        />
        {isMobile ? (
          <IconButton edge="end" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                color="primary"
                component={Link}
                to={item.path}
                sx={{ mx: 1 }}
              >
                {item.text}
              </Button>
            ))}
            <Button
              variant="contained"
              sx={{
                borderRadius: 20,
                ml: 1,
                backgroundColor: "#f47754",
                color: "white",
                "&:hover": {
                  backgroundColor: "#e05634",
                },
              }}
              href="https://lin.ee/oGoOopX"
              target="_blank"
              size="small"
            >
              LINEでお問い合わせ
            </Button>
          </Box>
        )}
        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {drawerList}
        </SwipeableDrawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
