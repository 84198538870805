import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  Divider,
} from "@mui/material";
import DOMPurify from "dompurify";
import { Job } from "../pages/JobsPage";
import { getFunctions, httpsCallable } from "firebase/functions";

interface InquiryResult {
  status: "redirect" | "sent";
  url?: string;
}

const handleContactClick = async (event: React.MouseEvent) => {
  event.preventDefault();
  const functions = getFunctions();
  const handleInquiryClick = httpsCallable<{ jobId: string }, InquiryResult>(
    functions,
    "handleInquiryClick"
  );

  try {
    const result = await handleInquiryClick({ jobId: "exampleID" });
    const data = result.data;

    if (data.status === "redirect" && data.url) {
      window.location.href = data.url;
    } else if (data.status === "sent") {
      alert("案件情報をLINEに送信しました。");
    } else {
      throw new Error("Unexpected response status");
    }
  } catch (error) {
    console.error("Error handling inquiry click:", error);
    alert("エラーが発生しました。もう一度お試しください。");
  }
};

interface JobDialogProps {
  job: Job | null;
  open: boolean;
  onClose: () => void;
}

const sanitizeHTML = (html: string) => ({
  __html: DOMPurify.sanitize(html.replace(/\n/g, "<br>"), {
    USE_PROFILES: { html: true },
  }),
});

const SanitizedContent: React.FC<{ content: string }> = ({ content }) => (
  <div dangerouslySetInnerHTML={sanitizeHTML(content)} />
);

const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <Typography
    variant="h5"
    gutterBottom
    sx={{ color: "primary.main", fontWeight: "bold" }}
  >
    {title}
  </Typography>
);

const JobImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <Box sx={{ mt: 2, mb: 3 }}>
    <img
      src={src}
      alt={alt}
      style={{
        width: "100%",
        maxHeight: "300px",
        objectFit: "cover",
        borderRadius: "4px",
      }}
    />
  </Box>
);

const JobDialog: React.FC<JobDialogProps> = ({ job, open, onClose }) => {
  if (!job) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{ bgcolor: "primary.main", color: "white", fontWeight: "bold" }}
      >
        {job.title}
      </DialogTitle>
      <DialogContent>
        <JobImage src={job.image?.url} alt={job.title} />
        <Chip
          label={`掲載日: ${new Date(job.publishedAt).toLocaleDateString()}`}
          sx={{ mb: 4 }}
        />
        <SectionTitle title="必須条件" />
        <SanitizedContent content={job.must} />
        <Divider sx={{ my: 4 }} />
        <SectionTitle title="詳細情報" />
        <SanitizedContent content={job.contents} />
        <Divider sx={{ my: 4 }} />
        <SectionTitle title="給与情報" />
        <Box sx={{ bgcolor: "background.paper", borderRadius: 1 }}>
          <SanitizedContent content={job.cost} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobDialog;
