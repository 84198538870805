import React, { useRef, useCallback, useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Grid,
  CardMedia,
  Chip,
  TextField,
  Button,
} from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getRecruits } from "../api/microCMS";
import JobDialog from "../components/JobDialog";

export interface Job {
  id: string;
  title: string;
  must: string;
  cost: string;
  contents: string;
  image: {
    height: number;
    url: string;
    width: number;
  };
  publishedAt: string;
  revisedAt: string;
  jobType: string;
}

const JobsPage = () => {
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [appliedSearchTerm, setAppliedSearchTerm] = useState("");
  const observer = useRef<IntersectionObserver | null>(null);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["jobs", appliedSearchTerm],
    queryFn: ({ pageParam = 0 }) =>
      getRecruits({ pageParam, searchTerm: appliedSearchTerm }),
    getNextPageParam: (lastPage) => {
      if (lastPage.contents.length === 0) return undefined;
      return lastPage.offset + lastPage.limit;
    },
    initialPageParam: 0,
  });

  const lastJobElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleCardClick = (job: Job) => {
    setSelectedJob(job);
  };

  const handleCloseDialog = () => {
    setSelectedJob(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAppliedSearchTerm(searchTerm);
    refetch();
  };

  if (status === "error") {
    return (
      <Container>
        <Typography variant="h4" gutterBottom>
          求人情報
        </Typography>
        <Typography variant="body1" color="error">
          求人情報の取得に失敗しました。
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 12, px: 2 }}>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "center",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          求人情報
        </Typography>
        <form onSubmit={handleSearchSubmit}>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            <TextField
              label="求人検索"
              name="searchTerm"
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              sx={{ flexGrow: 1, width: "100%" }}
              size="small"
            />
            <Button type="submit" variant="contained" color="primary">
              検索
            </Button>
          </Box>
        </form>
      </Box>

      {status === "pending" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {data.pages.map((page, i) => (
            <React.Fragment key={i}>
              {page.contents.map((job: Job, index: number) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={job.id}
                  ref={
                    page.contents.length === index + 1
                      ? lastJobElementRef
                      : null
                  }
                >
                  <Card
                    onClick={() => handleCardClick(job)}
                    sx={{
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: 3,
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={job.image?.url || ""}
                      alt={job.title}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        {job.title}
                      </Typography>
                      <Chip
                        label={`掲載日: ${new Date(
                          job.publishedAt
                        ).toLocaleDateString()}`}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      )}
      {isFetchingNextPage && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
      <JobDialog
        job={selectedJob}
        open={!!selectedJob}
        onClose={handleCloseDialog}
      />
    </Container>
  );
};

export default JobsPage;
