import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const BusinessPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sections = [
    {
      title: "仕事の流れ",
      content: `平均7:00-19:00\n倉庫にて荷物の積み込み\n→個人宅への配送\n決まったエリアで1日平均\n100-130個の荷物を運ぶ`,
      image: "images/flow.jpg", // 適切な画像ファイルを指定
    },
    {
      title: "配送物",
      content: "衣服、食品、薬品など、片手で持てるものが中心",
      image: "images/delivery.jpg", // 適切な画像ファイルを指定
    },
    {
      title: "特徴",
      content: `自由度が高い\n日々、違った出会いがある\n小さな限られたエリアでの配送`,
      image: "images/feature.jpg", // 適切な画像ファイルを指定
    },
    {
      title: "収益モデル",
      content: "完全歩合制",
      image: "images/revenue.jpg", // 適切な画像ファイルを指定
    },
  ];

  return (
    <Container disableGutters maxWidth={false} sx={{ mt: 12, px: 6 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
      >
        業務内容
      </Typography>
      <Box sx={{ position: "relative", textAlign: "center", mb: 4 }}>
        <img
          src="images/ukewatashi.png"
          alt="car"
          style={{
            width: "100%",
            maxHeight: isMobile ? "100px" : "200px", // 高さを制限して横長に見せる
            objectFit: "cover",
            height: "auto",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // 中央揃えに調整
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
            padding: theme.spacing(2),
            background: "rgba(0, 0, 0, 0.5)", // 画像の上に半透明のオーバーレイ
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h3"} // より大きなフォントサイズに調整
            sx={{
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
              mb: 1,
            }}
          >
            宅配配送
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h5"}
            sx={{ color: "white", maxWidth: "80%", textAlign: "center" }}
          >
            個人宅を中心に、荷物を運びます。
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        {sections.map((section, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette.primary.main,
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "left", color: "white" }}
              >
                {section.title}
              </Typography>
            </Paper>
            <Typography variant="body2" sx={{ textAlign: "left", mt: 2 }}>
              {section.content.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BusinessPage;
