import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  useMediaQuery,
  useTheme,
  Divider,
  CircularProgress,
  Container,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Job } from "../pages/JobsPage";
import { getRecruits } from "../api/microCMS";
import JobDialog from "../components/JobDialog";

const NewsSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getRecruits({ pageParam: 0 });
        setJobs(response.contents.slice(0, 2)); // 最新の2件のみを取得
      } catch (error) {
        setError("求人情報の取得に失敗しました。");
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
  };

  const handleCloseDialog = () => {
    setSelectedJob(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ py: 4 }}>
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Card
      sx={{
        px: { xs: 2, sm: 4 },
        py: 4,
        position: "relative",
        overflow: "visible",
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", mb: 4 }}>
        新着の求人情報
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {jobs.map((job, index) => (
          <React.Fragment key={job.id}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection={isMobile ? "column" : "row"}
                onClick={() => handleJobClick(job)}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: isMobile ? "100%" : 140,
                    height: isMobile ? "auto" : 160,
                    mr: isMobile ? 0 : 2,
                    mb: 0,
                  }}
                  image={job.image.url}
                  alt={job.title}
                />
                <CardContent>
                  <Typography
                    variant={isMobile ? "body1" : "h6"}
                    component="div"
                    sx={{ mb: 1 }}
                    fontWeight="bold"
                  >
                    {job.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    掲載日: {new Date(job.publishedAt).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            {index < jobs.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowForwardIcon />}
        sx={{
          position: "absolute",
          bottom: 10,
          right: 60,
          transform: "translate(50%, 50%)",
          fontSize: "1rem",
          padding: "12px 20px",
          zIndex: 10,
          ...(isMobile && {
            position: "static",
            transform: "none",
            width: "100%",
            marginTop: 2,
          }),
        }}
        onClick={() => {
          window.location.href = "jobs";
        }}
      >
        もっとみる
      </Button>
      <JobDialog
        job={selectedJob}
        open={!!selectedJob}
        onClose={handleCloseDialog}
      />
    </Card>
  );
};

export default NewsSection;
