import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#080973", // 画像のような青色
    },
    secondary: {
      main: "#f8f9fa", // メインのボタンやアイコンの色
    },
    warning: {
      main: "#e8e151", // 警告用の色
    },
    background: {
      default: "#f8f9fa", // 淡いグレーの背景色
      paper: "#ffffff", // セクションやカードの背景色
    },
    text: {
      primary: "#212529", // メインのテキストカラー（ダークグレー）
      secondary: "#212529", // サブテキストカラー（ライトグレー）
    },
  },
  typography: {
    fontFamily: ['"Yu Gothic"', '"Helvetica"', '"Arial"', "sans-serif"].join(
      ","
    ),
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.2,
      color: "#212529",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.3,
      color: "#212529",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
      lineHeight: 1.4,
      color: "#212529",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.5,
      color: "#212529",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: 1.6,
      color: "#212529",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.7,
      color: "#212529",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.7,
      color: "#212529",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      color: "#212529",
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
});

export default theme;
