import { createClient } from "microcms-js-sdk";

const client = createClient({
  serviceDomain: "5562",
  apiKey: "sVJUMoBUVKLJ4dBjrtQiRThgCj4RTYgE8DCz",
});

interface QueryParams {
  offset: number;
  limit: number;
  q?: string;
}

export const getRecruits = async ({ pageParam = 0, searchTerm = "" }) => {
  const queries: QueryParams = {
    offset: pageParam,
    limit: 10,
  };

  // キーワード検索
  if (searchTerm) {
    queries.q = searchTerm;
  }

  const response = await client.get({
    endpoint: "recruit",
    queries: queries,
  });

  return {
    contents: response.contents,
    totalCount: response.totalCount,
    offset: pageParam,
    limit: 10,
  };
};
