import React from "react";
import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InterviewsSection from "../components/InterviewsSection";
import NewsSection from "../components/NewsSection";
import TikTokSection from "../components/TikTokSection";
import LineCard from "../components/LineCard";

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <Box sx={{ position: "relative", textAlign: "center", mb: 4 }}>
          <img
            src="images/woman1.jpg"
            alt="car"
            style={{
              width: "100%",
              maxHeight: isMobile ? "300px" : "500px",
              objectFit: "cover",
              objectPosition: "top center",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              color: "white",
              pl: isMobile ? 2 : 3,
            }}
          >
            <Typography
              variant={isMobile ? "h3" : "h1"}
              sx={{
                fontWeight: "bold",
                mt: 12,
                color: "white",
                textAlign: "left",
              }}
            >
              物流が社会を支える
              <br />
              未来を開く
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          sx={{ px: 4, gap: 4, maxWidth: { xs: "90%", sm: "80%" }, mx: "auto" }}
        >
          <NewsSection />
          <InterviewsSection />
          <TikTokSection />
          <LineCard />
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
