import React from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const LineCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // スマホ判定

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        maxWidth: 600,
        p: 2,
        mb: 4,
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: isMobile ? "70%" : "150px", // 画像が切れないようにサイズ調整
          height: "auto", // 高さを自動で調整
          objectFit: "contain", // 画像の比率を保ちながら表示
          flexShrink: 0,
        }}
        image="https://qr-official.line.me/gs/M_369rhvds_GW.png?oat_content=qr"
        alt="LINE QR Code"
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Typography
          variant={isMobile ? "body2" : "h5"}
          gutterBottom
          sx={{ color: "#49c755", fontWeight: "bold" }}
        >
          LINEでお問い合わせ
        </Typography>
        <Typography
          variant={isMobile ? "body2" : "h5"}
          sx={{
            fontWeight: "bold",
            color: "#49c755",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          ID：@369rhvds
        </Typography>

        {isMobile && (
          <Button
            href="https://lin.ee/oGoOopX"
            target="_blank"
            color="primary"
            variant="contained"
            sx={{
              mt: 2,
              textTransform: "none",
              fontSize: "1rem",
              bgcolor: "#49c755",
              "&:hover": { bgcolor: "#45b349" },
              borderRadius: "30px",
              px: 4,

              whiteSpace: "nowrap",
            }}
          >
            友達追加する
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default LineCard;
