import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const AboutPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container disableGutters maxWidth={false} sx={{ mt: 12, px: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
      >
        会社概要
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: isMobile ? "100%" : "800px", mx: "auto" }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                variant="head"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4f6f8",
                  width: isMobile ? "35%" : "auto",
                }}
              >
                会社名
              </TableCell>
              <TableCell sx={{ width: isMobile ? "65%" : "auto" }}>
                ココロジ株式会社
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4f6f8",
                  width: isMobile ? "35%" : "auto",
                }}
              >
                業種
              </TableCell>
              <TableCell sx={{ width: isMobile ? "65%" : "auto" }}>
                物流業務受託
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4f6f8",
                  width: isMobile ? "35%" : "auto",
                }}
              >
                主な取引先
              </TableCell>
              <TableCell sx={{ width: isMobile ? "65%" : "auto" }}>
                ヤマト運輸
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4f6f8",
                  width: isMobile ? "35%" : "auto",
                }}
              >
                ミッション
              </TableCell>
              <TableCell sx={{ width: isMobile ? "65%" : "auto" }}>
                物流業界における効率化と品質向上を目指し、地域社会に貢献すること
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#f4f6f8",
                  width: isMobile ? "35%" : "auto",
                }}
              >
                サービス内容
              </TableCell>
              <TableCell sx={{ width: isMobile ? "65%" : "auto" }}>
                柔軟な物流サービスを提供し、信頼と実績を築いています。
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AboutPage;
