import React from "react";
import { Typography, Grid, Card, CardContent, CardMedia } from "@mui/material";

const dummyTikToks = [
  {
    id: "1",
    title: "TikTok動画1",
    description: "TikTok動画1の説明です。",
    image: "images/ai.png",
  },
  {
    id: "2",
    title: "TikTok動画2",
    description: "TikTok動画2の説明です。",
    image: "images/ai.png",
  },
  {
    id: "3",
    title: "TikTok動画3",
    description: "TikTok動画3の説明です。",
    image: "images/ai.png",
  },
];

const TikTokSection = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          TikTok
        </Typography>
      </Grid>
      {dummyTikToks.map((tiktok) => (
        <Grid item xs={12} sm={4} key={tiktok.id}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={tiktok.image}
              alt={tiktok.title}
            />
            <CardContent>
              <Typography variant="h6" component="div">
                {tiktok.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default TikTokSection;
