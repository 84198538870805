import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const dummyInterviews = [
  {
    id: "1",
    title: "ドライバーインタビュー1",
    content: "ドライバーインタビュー1の内容です。",
    image: "images/woman2.jpeg",
  },
  {
    id: "2",
    title: "ドライバーインタビュー2",
    content: "ドライバーインタビュー2の内容です。",
    image: "images/woman2.jpeg",
  },
  {
    id: "3",
    title: "ドライバーインタビュー3",
    content: "ドライバーインタビュー3の内容です。",
    image: "images/woman2.jpeg",
  },
];

const InterviewsSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          ドライバーインタビュー
        </Typography>
      </Grid>
      {dummyInterviews.map((interview) => (
        <Grid item xs={12} key={interview.id}>
          <Card sx={{ display: isMobile ? "block" : "flex" }}>
            <CardMedia
              component="img"
              sx={{
                width: isMobile ? "100%" : 300,
                height: isMobile ? 200 : "auto",
              }}
              image={interview.image}
              alt={interview.title}
            />
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {interview.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {interview.content}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default InterviewsSection;
