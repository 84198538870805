import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        p: 2,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        mt: "auto",
      }}
    >
      <Typography variant="body1" align="center" color="white">
        © 2024 ココロジ. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
